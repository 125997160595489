import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Loading, Button } from '../../src';
import { Row } from './helpers';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "loading"
    }}>{`Loading`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Loading from '@mfrm/mfcl/Loading'
`}</code></pre>
    <p>{`Loaders help the user feel like things are happening`}</p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <ul>
      <li parentName="ul">{`Pass down any props to the Loading Div`}
        <Props of={Loading} mdxType="Props" />
      </li>
    </ul>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={0} __code={'<Loading />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Loading,
      Button,
      Row,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Loading mdxType="Loading" />
    </Playground>
    <h2 {...{
      "id": "color"
    }}>{`Color`}</h2>
    <ul>
      <li parentName="ul">{`Pass in any color hex or RGB`}</li>
    </ul>
    <Playground __position={1} __code={'<Row>\n  <Loading color=\"blue\" />\n  <Loading color=\"red\" />\n  <Loading color=\"green\" />\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Loading,
      Button,
      Row,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <Loading color="blue" mdxType="Loading" />
    <Loading color="red" mdxType="Loading" />
    <Loading color="green" mdxType="Loading" />
  </Row>
    </Playground>
    <h2 {...{
      "id": "sizes"
    }}>{`Sizes`}</h2>
    <ul>
      <li parentName="ul">{`sm, md, lg, or any pixel value`}</li>
    </ul>
    <Playground __position={2} __code={'<Row>\n  <Loading size=\"sm\" />\n  <Loading size=\"md\" />\n  <Loading size=\"lg\" />\n  <Loading size=\"50px\" />\n  <Loading size=\"10px\" />\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Loading,
      Button,
      Row,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <Loading size="sm" mdxType="Loading" />
    <Loading size="md" mdxType="Loading" />
    <Loading size="lg" mdxType="Loading" />
    <Loading size="50px" mdxType="Loading" />
    <Loading size="10px" mdxType="Loading" />
  </Row>
    </Playground>
    <h2 {...{
      "id": "can-be-used-with-a-button"
    }}>{`Can be used with a button`}</h2>
    <Playground __position={3} __code={'<Button loading size=\"lg\">\n  Submit\n</Button>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Loading,
      Button,
      Row,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Button loading size="lg" mdxType="Button">
    Submit
  </Button>
    </Playground>
    <h2 {...{
      "id": "dots"
    }}>{`Dots`}</h2>
    <Playground __position={4} __code={'<Row>\n  <Loading type=\"dots\" size=\"sm\" />\n  <Loading type=\"dots\" size=\"md\" />\n  <Loading type=\"dots\" size=\"lg\" />\n  <Loading type=\"dots\" size=\"80px\" />\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Loading,
      Button,
      Row,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <Loading type="dots" size="sm" mdxType="Loading" />
    <Loading type="dots" size="md" mdxType="Loading" />
    <Loading type="dots" size="lg" mdxType="Loading" />
    <Loading type="dots" size="80px" mdxType="Loading" />
  </Row>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      